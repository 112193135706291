<template>
    <div class="page-box">
        <div class="page-title flex-center">
            <span>当前位置：项目管理 > <b>到账统计</b></span>
        </div>
        <div class="project-info">
            <div class="project-condition">
                <div class="flex-center">
                    <el-select v-model="sid" placeholder="请选择所属学会">
                        <el-option
                            v-for="item in societyOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <el-date-picker
                        v-model="start_end_time"
                        :default-time="['00:00:00','23:59:59']"
                        clearable
                        end-placeholder="结束日期"
                        start-placeholder="开始日期"
                        type="daterange" value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                    <el-input
                        v-model="search_content"
                        class="margin-left-sm"
                        placeholder="请输入关键字"
                    ></el-input>

                    <el-button class="search" type="primary" @click="search">搜索</el-button>
                    <el-button class="margin-left" type="primary" @click="downFile()">导出</el-button>

                </div>
            </div>

            <div class="settlement-tab">
                <el-table
                    :data="settlement"
                    :summary-method="getSummaries"
                    border
                    header-row-class-name="table-header"
                    show-summary
                    style="width: 100%">
                    <el-table-column align="center" label="序号" type="index" width="90">
                    </el-table-column>
                    <el-table-column align="center" label="日期" prop="sp_time" width="110">
                    </el-table-column>
                    <el-table-column align="center" label="收入(万元)" prop="money1" width="130">
                        <template slot-scope="scope">
                            {{ scope.row.money1 || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="支出(万元)" prop="money2" width="130">
                        <template slot-scope="scope">
                            {{ scope.row.money2 || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="单位名称" prop="corporate_name" width="200">
                        <template slot-scope="scope">
                            {{ scope.row.corporate_name || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="会议时间及日期" prop="project_info">
                    </el-table-column>
                    <el-table-column align="center" label="学会负责人" prop="true_name" width="120">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config/index"

export default {
    name: "to_statistics",
    data() {
        return {
            search_content: '',
            start_end_time: [],
            societyOptions: [],       //  学会
            sid: '',
            settlement: [],
        };
    },
    created() {
        this.$emit("setIndex", [1, 3]);
        this.init();

    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    methods: {
        init() {
            this.loading = true
            // 获取学会列表
            this.$api.apiContent.getSociety({
                token: this.$store.state.user.token,
                timestamp: this.$tools.requestTime(),
                sign: this.$tools.SHA256({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                }, this.$store.state.user.key)
            }).then(res => {
                this.societyOptions = res.data
                this.sid = res.data[0].id
                this.getList()
            }).catch(() => {
                this.societyOptions = []
            })

            this.loading = false
        },
        search() {
            this.getList()
        },
        getList() {
            this.$api.apiContent
                .financial_statistics({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    search_sid: this.sid,
                    search_company_name: this.search_content,
                    search_start_sp_time: this.start_end_time.length ? this.start_end_time[0] : '',
                    search_end_sp_time: this.start_end_time.length ? this.start_end_time[1] : '',
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            search_sid: this.sid,
                            search_company_name: this.search_content,
                            search_start_sp_time: this.start_end_time.length ? this.start_end_time[0] : '',
                            search_end_sp_time: this.start_end_time.length ? this.start_end_time[1] : '',
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.settlement = res.data.data
                });
        },
        //  返回
        back() {
            this.$router.go(-1);
        },
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总价'
                    return
                } else if (index === 2 || index === 3) {
                    const values = data.map(item => Number(item[column.property]))
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0);
                    sums[index] = sums[index].toFixed(6)
                    // sums[index] += '万元'
                } else {
                    sums[index] = '--'
                    return
                }

            })
            return sums;
        },
        downFile() {
            window.open(`${config.urlStr}/home/financial/exportExcel?search_sid=${this.sid}&search_company_name=${this.search_content
            }&search_start_sp_time=${this.start_end_time.length ? this.start_end_time[0] : ''}&search_end_sp_time=${this.start_end_time.length ? this.start_end_time[1] : ''}`)
        }
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .project-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        overflow: hidden;
        min-height: 750px;

        .project-condition {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 24px 28px 16px;
            border-bottom: 2px solid #f5f5f5;

            .title-tab {
                .active {
                    background: #3b77e7;
                    color: #fff;
                }

                .settlement {
                    margin-left: 10px;
                }
            }

            .el-select {
                margin-right: 20px;
            }

            .el-input {
                margin-right: 20px;
                width: 268px;
            }
        }

        .settlement-tab {
            padding: 30px;
        }
    }
}
</style>
